import * as Yup from 'yup'
import {
  PrefilledIntakeNote,
  IntakeNoteEmployment,
  IntakeNoteEmploymentStatus,
  IntakeNoteHealthHistory,
  IntakeNoteExpectedAchievements,
} from 'src/graphql.generated'

import { BillingCode } from './billingCodeConstants'

type ConsentSchema = {
  PARTICIPANT_INELIGIBLE?: boolean
  REVIEWED_CONFIDENTIALITY_AND_EXCEPTIONS?: boolean
  VERBAL_CONSENT_FOR_TREATMENT?: boolean
}

export type IntakeNoteSchema = PrefilledIntakeNote & {
  __version: 3
  CONSENT: ConsentSchema
  OBSTACLES_AND_ACTION_PLAN?: string
  IDENTIFYING_INFO?: string
  EXPECTATIONS_AND_INTERESTS?: string
  TREATMENT_GOALS?: string
  SUICIDE_RISK?: string
  INTERNAL_EXTERNAL_STRESSORS?: string
  TIMELINE_OF_SYMPTOMS_AND_TREATMENT?: string
  STARTING_DATE_CONFIRMED?: boolean
  CREATE_QUICK_NOTE?: boolean
  MAJOR_DEPRESSION_EPISODES?: number
  BILLING_CODES?: BillingCode[]
  PARTICIPANT_INELIGIBLE?: boolean
}

export const EMPTY_FIELDS: IntakeNoteSchema = {
  __version: 3,
  CONSENT: {
    REVIEWED_CONFIDENTIALITY_AND_EXCEPTIONS: false,
    VERBAL_CONSENT_FOR_TREATMENT: false,
  },
  BILLING_CODES: [],
}

type SchemaTypes = Yup.StringSchema | Yup.BooleanSchema

const checkedWhenFalse: Yup.WhenOptions<unknown> = {
  is: true,
  then: (s: SchemaTypes) => s.notRequired(),
  otherwise: (s: Yup.MixedSchema) => s.oneOf([true]).required(),
}

const requiredWhenFalse: Yup.WhenOptions<unknown> = {
  is: true,
  then: (s: SchemaTypes) => s.notRequired(),
  otherwise: (s: SchemaTypes) => s.required(),
}

const CONSENT_SCHEMA: Yup.ObjectSchema<ConsentSchema> = Yup.object()
  .when('PARTICIPANT_INELIGIBLE', {
    is: false,
    then: Yup.object<ConsentSchema>({
      REVIEWED_CONFIDENTIALITY_AND_EXCEPTIONS: Yup.boolean().default(false).oneOf([true]),
      VERBAL_CONSENT_FOR_TREATMENT: Yup.boolean().default(false).oneOf([true]),
    }),
    otherwise: Yup.object<ConsentSchema>({
      REVIEWED_CONFIDENTIALITY_AND_EXCEPTIONS: Yup.boolean().default(false),
      VERBAL_CONSENT_FOR_TREATMENT: Yup.boolean().default(false),
    }),
  })
  .default({})
  .required()

// When the complexity of the type definitions for the intake note schema exceeded a certain
// threshold, they seem to expose a TS bug. The complier directive is used as a workaround
// for allowing for otherwise runnable code.
const HEALTH_HISTORY_SCHEMA: Yup.ObjectSchema<IntakeNoteHealthHistory> = Yup.object({
  THERAPY: Yup.boolean().default(false).required(),
  SUICIDALITY: Yup.boolean().default(false).required(),
  GENERALISED_ANXIETY: Yup.boolean().default(false).required(),
  DEPRESSION: Yup.boolean().default(false).required(),
  BIPOLAR: Yup.boolean().default(false).required(),
  PTSD: Yup.boolean().default(false).required(),
  DTD: Yup.boolean().default(false).required(),
  PSYCHOSIS: Yup.boolean().default(false).required(),
  SUBSTANCE_ABUSE: Yup.boolean().default(false).required(),
}).required()

const EXPECTED_ACHIEVEMENTS_SCHEMA: Yup.ObjectSchema<IntakeNoteExpectedAchievements> = Yup.object({
  CONTROL_EMOTIONS_AND_BEHAVIOUR: Yup.boolean().default(false).required(),
  MOTIVATION_AND_ENERGY: Yup.boolean().default(false).required(),
  OTHER: Yup.boolean().default(false).required(),
  OTHER_REASON: Yup.string().nullable(),
  SELF_ESTEEM_AND_CONFIDENCE: Yup.boolean().default(false).required(),
  SENSE_OF_CALM: Yup.boolean().default(false).required(),
}).required()

const EMPLOYMENT_SCHEMA: Yup.ObjectSchema<IntakeNoteEmployment> = Yup.object({
  STATUS: Yup.string().oneOf(Object.values(IntakeNoteEmploymentStatus)).nullable(),
})
  .default({})
  .required()

export const INTAKE_NOTE_SCHEMA = Yup.object<IntakeNoteSchema>({
  __version: Yup.mixed<3>().oneOf([3]).default(3),
  PARTICIPANT_INELIGIBLE: Yup.boolean().default(false).required(),
  CONSENT: CONSENT_SCHEMA,
  HEALTH_HISTORY: HEALTH_HISTORY_SCHEMA,
  EXPECTED_ACHIEVEMENTS: EXPECTED_ACHIEVEMENTS_SCHEMA,
  EMPLOYMENT: EMPLOYMENT_SCHEMA,
  TIMELINE_OF_SYMPTOMS_AND_TREATMENT: Yup.string().when('PARTICIPANT_INELIGIBLE', requiredWhenFalse),
  OBSTACLES: Yup.string().nullable(),
  IDENTIFYING_INFO: Yup.string().when('PARTICIPANT_INELIGIBLE', requiredWhenFalse),
  EXPECTATIONS_AND_INTERESTS: Yup.string().when('PARTICIPANT_INELIGIBLE', requiredWhenFalse),
  TREATMENT_GOALS: Yup.string().when('PARTICIPANT_INELIGIBLE', requiredWhenFalse),
  SUICIDE_RISK: Yup.string().when('PARTICIPANT_INELIGIBLE', requiredWhenFalse),
  OBSTACLES_AND_ACTION_PLAN: Yup.string().when('PARTICIPANT_INELIGIBLE', requiredWhenFalse),
  STARTING_DATE_CONFIRMED: Yup.boolean().when('PARTICIPANT_INELIGIBLE', checkedWhenFalse),
  CREATE_QUICK_NOTE: Yup.boolean().when('PARTICIPANT_INELIGIBLE', checkedWhenFalse),
  MAJOR_DEPRESSION_EPISODES: Yup.number().when('PARTICIPANT_INELIGIBLE', requiredWhenFalse),

  BILLING_CODES: Yup.array<BillingCode>().default([]).when('PARTICIPANT_INELIGIBLE', requiredWhenFalse),
}).required()

export type FormikContext = {
  value?: IntakeNoteSchema
}
