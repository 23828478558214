import { useFirebase } from 'src/firebase'
import { Program, PatientHistoryType } from 'src/legacy/models/firebase'

const EMPTY_RESPONSE: Array<PatientHistoryType> = []

export default function useLoadPatientHistoryTypes(programID: string | null) {
  const programPath = programID ? `programs/${programID}` : null
  const { data } = useFirebase<Program>(programPath)

  return data?.patientHistoryTypes || EMPTY_RESPONSE
}
