/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Stack, Skeleton, Alert } from '@mui/material'
import { useGetPatientHistoryAndProgramStartDateQuery } from 'src/participantHistory/patientHistoryQueries.generated'

import ParticipantHistoryEntry from './participantHistoryEntry/ParticipantHistoryEntry'

type Props = { uid: string }

const LoadingSkeleton = () => (
  <Stack spacing={2}>
    {[...Array(5)].map((_, index) => (
      <div key={index}>
        <Skeleton width={300} />
        <Skeleton width={400} />
      </div>
    ))}
  </Stack>
)

const ParticipantHistoryList = ({ uid }: Props) => {
  const { data, loading, error } = useGetPatientHistoryAndProgramStartDateQuery({ variables: { uid } })

  if (loading && !data) {
    return <LoadingSkeleton />
  }

  if (!loading && error) {
    return <Alert severity="error">Failed to load the participant&apos;s history entries</Alert>
  }

  return (
    <Stack spacing={2}>
      {data?.patientHistory.map((entry) => (
        <ParticipantHistoryEntry
          key={entry.entryID}
          entry={entry}
          uid={uid}
          programStartDate={data.patient.enrollment.programStartDate}
        />
      ))}
    </Stack>
  )
}

export default ParticipantHistoryList
