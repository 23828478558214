export type PossibleTypesResultData = {
  possibleTypes: {
    AfterAppLoadScreen: ['AfterAppLoadGetStartedScreen', 'AfterAppLoadSignupFormScreen']
    AvailableVideoCallSlotsResult: ['AvailableVideoCallSlots', 'VideoCall']
    CancelVideoCallByParticipantResult: ['InAppVideoCallResult']
    CommunityPost: ['CommunityPostQuestion']
    CommunityPostReply: ['CommunityPostReplyQuestionAnswer']
    CommunityPostReplyAuthor: ['CommunityFacilitator']
    CommunityPostReplyResult: ['CommunityPostReplySended', 'CommunityPostReplySendingPrevented']
    CostEstimateLookup: ['CostEstimateLookupFailure', 'CostEstimateLookupSuccess']
    CreateChatMessageResult: ['ChatMessageCreated']
    EditChatMessageResult: ['ChatMessageEdited']
    FeedItem: ['ChatMessage', 'PatientResponse', 'ResumedPatientResponse', 'SmsMessage', 'VideoCall']
    FeedResult: ['FeedSuccess']
    InAppCostEstimateComponent: [
      'InAppCostEstimateAlertComponent',
      'InAppCostEstimateExpandableComponent',
      'InAppCostEstimateFeeGroupComponent',
      'InAppCostEstimateHorizontalDividerComponent',
      'InAppCostEstimateIconTextListComponent',
      'InAppCostEstimateMultiTextInRowComponent',
      'InAppCostEstimateParagraphComponent',
      'InAppCostEstimateTimelineComponent',
      'InAppCostEstimateTitleComponent'
    ]
    InAppPaymentPlanScreenPaymentSummary: [
      'InAppPaymentPlanScreenPaymentSummaryInProgram',
      'InAppPaymentPlanScreenPaymentSummaryInProgramNoUpcomingInvoices',
      'InAppPaymentPlanScreenPaymentSummaryIntake'
    ]
    InAppProfileFormInputField: [
      'InAppProfileFormMultiItemSelector',
      'InAppProfileFormSingleItemSelector',
      'InAppProfileFormTextInput'
    ]
    InAppProfileFormItemSelector: ['InAppProfileFormMultiItemSelector', 'InAppProfileFormSingleItemSelector']
    InAppPromptActionPanelActionButton: ['InAppPromptActionPanelActionButtonDeepLink']
    InAppSignupFormResult: ['InAppSignupFormFound', 'InAppSignupFormNotFound']
    MarkChatMessagesAsSeenResult: ['UnseenChatMessages']
    ParticipantRegistrationResult: ['ParticipantRegistered', 'ParticipantRegistrationFailed']
    PatientHistory: ['CommonPatientHistory', 'FinancialEligibilityPatientHistory', 'VideoCallPatientHistory']
    RescheduleVideoCallByParticipantResult: ['OpenScheduleVideoCallLesson']
    ScheduleVideoCallByParticipantResult: ['VideoCall', 'VideoCallSlotTaken']
    SignupFormInput: [
      'SignupFormConsents',
      'SignupFormDateSelector',
      'SignupFormEmailInput',
      'SignupFormItemSelector',
      'SignupFormPhoneNumberInput',
      'SignupFormTextInput'
    ]
    SignupFormScreen: ['SignupFormIsDependentScreen', 'SignupFormRegularScreen']
    StorePaymentMethodResult: ['StorePaymentMethodResultSuccess']
    VideoCallBookingInformation: ['InProgramVideoCallBookingInformation', 'IntakeVideoCallBookingInformation']
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AfterAppLoadScreen: ['AfterAppLoadGetStartedScreen', 'AfterAppLoadSignupFormScreen'],
    AvailableVideoCallSlotsResult: ['AvailableVideoCallSlots', 'VideoCall'],
    CancelVideoCallByParticipantResult: ['InAppVideoCallResult'],
    CommunityPost: ['CommunityPostQuestion'],
    CommunityPostReply: ['CommunityPostReplyQuestionAnswer'],
    CommunityPostReplyAuthor: ['CommunityFacilitator'],
    CommunityPostReplyResult: ['CommunityPostReplySended', 'CommunityPostReplySendingPrevented'],
    CostEstimateLookup: ['CostEstimateLookupFailure', 'CostEstimateLookupSuccess'],
    CreateChatMessageResult: ['ChatMessageCreated'],
    EditChatMessageResult: ['ChatMessageEdited'],
    FeedItem: ['ChatMessage', 'PatientResponse', 'ResumedPatientResponse', 'SmsMessage', 'VideoCall'],
    FeedResult: ['FeedSuccess'],
    InAppCostEstimateComponent: [
      'InAppCostEstimateAlertComponent',
      'InAppCostEstimateExpandableComponent',
      'InAppCostEstimateFeeGroupComponent',
      'InAppCostEstimateHorizontalDividerComponent',
      'InAppCostEstimateIconTextListComponent',
      'InAppCostEstimateMultiTextInRowComponent',
      'InAppCostEstimateParagraphComponent',
      'InAppCostEstimateTimelineComponent',
      'InAppCostEstimateTitleComponent',
    ],
    InAppPaymentPlanScreenPaymentSummary: [
      'InAppPaymentPlanScreenPaymentSummaryInProgram',
      'InAppPaymentPlanScreenPaymentSummaryInProgramNoUpcomingInvoices',
      'InAppPaymentPlanScreenPaymentSummaryIntake',
    ],
    InAppProfileFormInputField: [
      'InAppProfileFormMultiItemSelector',
      'InAppProfileFormSingleItemSelector',
      'InAppProfileFormTextInput',
    ],
    InAppProfileFormItemSelector: ['InAppProfileFormMultiItemSelector', 'InAppProfileFormSingleItemSelector'],
    InAppPromptActionPanelActionButton: ['InAppPromptActionPanelActionButtonDeepLink'],
    InAppSignupFormResult: ['InAppSignupFormFound', 'InAppSignupFormNotFound'],
    MarkChatMessagesAsSeenResult: ['UnseenChatMessages'],
    ParticipantRegistrationResult: ['ParticipantRegistered', 'ParticipantRegistrationFailed'],
    PatientHistory: ['CommonPatientHistory', 'FinancialEligibilityPatientHistory', 'VideoCallPatientHistory'],
    RescheduleVideoCallByParticipantResult: ['OpenScheduleVideoCallLesson'],
    ScheduleVideoCallByParticipantResult: ['VideoCall', 'VideoCallSlotTaken'],
    SignupFormInput: [
      'SignupFormConsents',
      'SignupFormDateSelector',
      'SignupFormEmailInput',
      'SignupFormItemSelector',
      'SignupFormPhoneNumberInput',
      'SignupFormTextInput',
    ],
    SignupFormScreen: ['SignupFormIsDependentScreen', 'SignupFormRegularScreen'],
    StorePaymentMethodResult: ['StorePaymentMethodResultSuccess'],
    VideoCallBookingInformation: ['InProgramVideoCallBookingInformation', 'IntakeVideoCallBookingInformation'],
  },
}
export default result
