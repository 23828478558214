import { useFormikContext } from 'formik'
import { merge } from 'lodash'
import { useCallback } from 'react'
import { IntakeNoteEmploymentStatus } from 'src/graphql.generated'
import { INTAKE_NOTE_SCHEMA } from './intakeNoteSchemas'
import { BillingCode } from './billingCodeConstants'

const DUMMY_TEXT = 'The quick brown fox jumps over a lazy dog'

export const DUMMY_INTAKE_NOTE = merge(INTAKE_NOTE_SCHEMA.default(), {
  IDENTIFYING_INFO: DUMMY_TEXT,
  EXPECTATIONS_AND_INTERESTS: DUMMY_TEXT,
  EMPLOYMENT: {
    STATUS: IntakeNoteEmploymentStatus.Working,
  },
  HEALTH_HISTORY: {
    THERAPY: true,
  },
  TIMELINE_OF_SYMPTOMS_AND_TREATMENT: DUMMY_TEXT,
  MAJOR_DEPRESSION_EPISODES: 1,
  SUICIDE_RISK: DUMMY_TEXT,
  BILLING_CODES: [BillingCode.F10_10],
  EXPECTED_ACHIEVEMENTS: {
    CONTROL_EMOTIONS_AND_BEHAVIOUR: true,
  },
  TREATMENT_GOALS: DUMMY_TEXT,
  OBSTACLES_AND_ACTION_PLAN: DUMMY_TEXT,
  STARTING_DATE_CONFIRMED: true,
  CONSENT: {
    VERBAL_CONSENT_FOR_TREATMENT: true,
    REVIEWED_CONFIDENTIALITY_AND_EXCEPTIONS: true,
  },
  CREATE_QUICK_NOTE: true,
})

export default function useAutofillIntakeNote() {
  const { setFieldValue } = useFormikContext()

  return useCallback(() => setFieldValue('value', DUMMY_INTAKE_NOTE), [setFieldValue])
}
